
// Overrides user components-variables
@import "../../../dme-common/assets/src/stack-admin/src/scss/bootstrap/functions";

@import "../variables/variables";
@import "../../bootstrap/variables";

// Google Color Palette defined: http://www.google.com/design/spec/style/color.html

$white: (
  "base": #FFFFFF,
);
$black: (
  "base": #000000,
);
$primary: (
  "lighten-5": lighten(theme-color("primary"), 25%),
  "lighten-4": lighten(theme-color("primary"), 20%),
  "lighten-3": lighten(theme-color("primary"), 15%),
  "lighten-2": lighten(theme-color("primary"), 10%),
  "lighten-1": lighten(theme-color("primary"), 5%),
  "base": theme-color("primary"),
  "darken-1": darken(theme-color("primary"), 5%),
  "darken-2": darken(theme-color("primary"), 10%),
  "darken-3": darken(theme-color("primary"), 15%),
  "darken-4": darken(theme-color("primary"), 20%),
  "accent-1":   #BDFDFF,
  "accent-2":   #8AFBFF,
  "accent-3":   #57FAFF,
  "accent-4":   #3DF9FF
);
$secondary: (
   "lighten-5": lighten(theme-color("secondary"), 25%),
   "lighten-4": lighten(theme-color("secondary"), 20%),
   "lighten-3": lighten(theme-color("secondary"), 15%),
   "lighten-2": lighten(theme-color("secondary"), 10%),
   "lighten-1": lighten(theme-color("secondary"), 5%),
   "base": theme-color("secondary"),
   "darken-1": darken(theme-color("secondary"), 5%),
   "darken-2": darken(theme-color("secondary"), 10%),
   "darken-3": darken(theme-color("secondary"), 15%),
   "darken-4": darken(theme-color("secondary"), 20%),
);
$success: (
  "lighten-5": lighten(theme-color("success"), 25%),
  "lighten-4": lighten(theme-color("success"), 20%),
  "lighten-3": lighten(theme-color("success"), 15%),
  "lighten-2": lighten(theme-color("success"), 10%),
  "lighten-1": lighten(theme-color("success"), 5%),
  "base": theme-color("success"),
  "darken-1": darken(theme-color("success"), 5%),
  "darken-2": darken(theme-color("success"), 10%),
  "darken-3": darken(theme-color("success"), 15%),
  "darken-4": darken(theme-color("success"), 20%),
  "accent-1":   #E1FFF1,
  "accent-2":   #AEFFD9,
  "accent-3":   #7BFFC1,
  "accent-4":   #62FFB5
);
$info: (
  "lighten-5": lighten(theme-color("info"), 25%),
  "lighten-4": lighten(theme-color("info"), 20%),
  "lighten-3": lighten(theme-color("info"), 15%),
  "lighten-2": lighten(theme-color("info"), 10%),
  "lighten-1": lighten(theme-color("info"), 5%),
  "base": theme-color("info"),
  "darken-1": darken(theme-color("info"), 5%),
  "darken-2": darken(theme-color("info"), 10%),
  "darken-3": darken(theme-color("info"), 15%),
  "darken-4": darken(theme-color("info"), 20%),
  "accent-1":   #FEFFFF,
  "accent-2":   #CBF5FF,
  "accent-3":   #98ECFF,
  "accent-4":   #7FE7FF
);
$warning: (
  "lighten-5": lighten(theme-color("warning"), 25%),
  "lighten-4": lighten(theme-color("warning"), 20%),
  "lighten-3": lighten(theme-color("warning"), 15%),
  "lighten-2": lighten(theme-color("warning"), 10%),
  "lighten-1": lighten(theme-color("warning"), 5%),
  "base": theme-color("warning"),
  "darken-1": darken(theme-color("warning"), 5%),
  "darken-2": darken(theme-color("warning"), 10%),
  "darken-3": darken(theme-color("warning"), 15%),
  "darken-4": darken(theme-color("warning"), 20%),
  "accent-1":   #FFF5EF,
  "accent-2":   #FFE5D8,
  "accent-3":   #FFF6F3,
  "accent-4":   #FFE3DA
);
$danger: (
  "lighten-5": lighten(theme-color("danger"), 25%),
  "lighten-4": lighten(theme-color("danger"), 20%),
  "lighten-3": lighten(theme-color("danger"), 15%),
  "lighten-2": lighten(theme-color("danger"), 10%),
  "lighten-1": lighten(theme-color("danger"), 5%),
  "base": theme-color("danger"),
  "darken-1": darken(theme-color("danger"), 5%),
  "darken-2": darken(theme-color("danger"), 10%),
  "darken-3": darken(theme-color("danger"), 15%),
  "darken-4": darken(theme-color("danger"), 20%),
  "accent-1":   #FFEEF1,
  "accent-2":   #FFD6DB,
  "accent-3":   #FFECEE,
  "accent-4":   #FFD3D7
);

$red: (
  "lighten-5":  #FFEBEE,
  "lighten-4":  #FFCDD2,
  "lighten-3":  #EF9A9A,
  "lighten-2":  #E57373,
  "lighten-1":  #EF5350,
  "base":       #F44336,
  "darken-1":   #E53935,
  "darken-2":   #D32F2F,
  "darken-3":   #C62828,
  "darken-4":   #B71C1C,
  "accent-1":   #FF8A80,
  "accent-2":   #FF5252,
  "accent-3":   #FF1744,
  "accent-4":   #D50000
);

$pink: (
  "lighten-5":  #FCE4EC,
  "lighten-4":  #F8BBD0,
  "lighten-3":  #F48FB1,
  "lighten-2":  #F06292,
  "lighten-1":  #EC407A,
  "base":       #E91E63,
  "darken-1":   #D81B60,
  "darken-2":   #C2185B,
  "darken-3":   #AD1457,
  "darken-4":   #880E4F,
  "accent-1":   #FF80AB,
  "accent-2":   #FF4081,
  "accent-3":   #F50057,
  "accent-4":   #C51162
);

$purple: (
  "lighten-5":  #F3E5F5,
  "lighten-4":  #E1BEE7,
  "lighten-3":  #CE93D8,
  "lighten-2":  #BA68C8,
  "lighten-1":  #AB47BC,
  "base":       #9C27B0,
  "darken-1":   #8E24AA,
  "darken-2":   #7B1FA2,
  "darken-3":   #6A1B9A,
  "darken-4":   #4A148C,
  "accent-1":   #EA80FC,
  "accent-2":   #E040FB,
  "accent-3":   #D500F9,
  "accent-4":   #DD00FF
);

$blue: (
  "lighten-5":  #E3F2FD,
  "lighten-4":  #BBDEFB,
  "lighten-3":  #90CAF9,
  "lighten-2":  #64B5F6,
  "lighten-1":  #42A5F5,
  "base":       #0075E1,
  "darken-1":   #1E88E5,
  "darken-2":   #1976D2,
  "darken-3":   #1565C0,
  "darken-4":   #0D47A1,
  "accent-1":   #82B1FF,
  "accent-2":   #448AFF,
  "accent-3":   #2979FF,
  "accent-4":   #2962FF
);

$cyan: (
  "lighten-5":  #E0F7FA,
  "lighten-4":  #B2EBF2,
  "lighten-3":  #80DEEA,
  "lighten-2":  #4DD0E1,
  "lighten-1":  #26C6DA,
  "base":       #00BCD4,
  "darken-1":   #00ACC1,
  "darken-2":   #0097A7,
  "darken-3":   #00838F,
  "darken-4":   #006064,
  "accent-1":   #84FFFF,
  "accent-2":   #18FFFF,
  "accent-3":   #00E5FF,
  "accent-4":   #00B8D4
);

$teal: (
  "lighten-5":  #E0F2F1,
  "lighten-4":  #B2DFDB,
  "lighten-3":  #80CBC4,
  "lighten-2":  #4DB6AC,
  "lighten-1":  #26A69A,
  "base":       #009688,
  "darken-1":   #00897B,
  "darken-2":   #00796B,
  "darken-3":   #00695C,
  "darken-4":   #004D40,
  "accent-1":   #A7FFEB,
  "accent-2":   #64FFDA,
  "accent-3":   #1DE9B6,
  "accent-4":   #00BFA5
);

$yellow: (
  "lighten-5":  #FFFDE7,
  "lighten-4":  #FFF9C4,
  "lighten-3":  #FFF59D,
  "lighten-2":  #FFF176,
  "lighten-1":  #FFEE58,
  "base":       #FFEB3B,
  "darken-1":   #FDD835,
  "darken-2":   #FBC02D,
  "darken-3":   #F9A825,
  "darken-4":   #F57F17,
  "accent-1":   #FFFF8D,
  "accent-2":   #FFFF00,
  "accent-3":   #FFEA00,
  "accent-4":   #FFD600
);

$amber: (
  "lighten-5":  #FFF8E1,
  "lighten-4":  #FFECB3,
  "lighten-3":  #FFE082,
  "lighten-2":  #FFD54F,
  "lighten-1":  #FFCA28,
  "base":       #FFC107,
  "darken-1":   #FFB300,
  "darken-2":   #FFA000,
  "darken-3":   #FF8F00,
  "darken-4":   #FF6F00,
  "accent-1":   #FFE57F,
  "accent-2":   #FFD740,
  "accent-3":   #FFC400,
  "accent-4":   #FFAB00
);

$blue-grey: (
  "lighten-5":  #ECEFF1,
  "lighten-4":  #CFD8DC,
  "lighten-3":  #B0BEC5,
  "lighten-2":  #90A4AE,
  "lighten-1":  #78909C,
  "base":       #607D8B,
  "darken-1":   #546E7A,
  "darken-2":   #455A64,
  "darken-3":   #37474F,
  "darken-4":   #263238
);

$grey-blue: (
  "lighten-5":  #ECEFF1,
  "lighten-4":  #CFD8DC,
  "lighten-3":  #B0BEC5,
  "lighten-2":  #6F85AD,
  "lighten-1":  #78909C,
  "base":       #1B2942,
  "darken-1":   #546E7A,
  "darken-2":   #404E67,
  "darken-3":   #37474F,
  "darken-4":   #263238
);

$shades: (
  "black":        #000000,
  "white":        #FFFFFF,
  "transparent":  transparent
);

$colors: (
  "white": $white,
  "black": $black,
  "primary": $primary,
  "secondary": $secondary,
  "success": $success,
  "info": $info,
  "warning": $warning,
  "danger": $danger,
  "red": $red,
  "pink": $pink,
  "purple": $purple,
  "blue": $blue,
  "cyan": $cyan,
  "teal": $teal,
  "yellow": $yellow,
  "amber": $amber,
  "blue-grey": $blue-grey,
  "grey-blue": $grey-blue,
  "shades": $shades
);
